<template>
  <div ref="filtersRef" class="filters" :class="{ 'filters--hidden': !isOpen }">
    <LineUpHeading
      v-if="heading"
      tag="button"
      class="heading-toggle"
      @click="toggle"
    >
      <span>{{ selectedOption?.label }}</span>
      <div class="toggle-icon">
        <ChevronDownIcon class="chevron" aria-hidden="true" role="img" />
      </div>
    </LineUpHeading>
    <button v-else class="toggle t-caption lg-down" @click="toggle">
      <span class="active-filter">
        {{ selectedOption?.label }}
      </span>
      <span class="toggle-icon">
        <ChevronDownIcon class="chevron" aria-hidden="true" role="img" />
      </span>
    </button>
    <div class="options">
      <NuxtLink
        v-for="option in options"
        :key="option.value"
        class="option t-h4"
        :class="{
          'option--active': selectedOption?.value === option.value
        }"
        :to="{
          query: {
            ...route.query,
            [queryKey]: option.value
          }
        }"
      >
        <div class="option-text">
          <span :aria-hidden="!!option.ariaLabel">{{ option.label }}</span>
          <span v-if="option.ariaLabel" class="visually-hidden">
            {{ option.ariaLabel }}
          </span>
        </div>
        <CheckIcon class="check" aria-hidden="true" role="img" />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside, onKeyStroke } from '@vueuse/core'

import CheckIcon from '@/assets/icons/check.svg?component'
import ChevronDownIcon from '@/assets/icons/chevron-down.svg?component'

type Option = { label: string; value: string | undefined; ariaLabel?: string }

const props = defineProps<{
  options: Option[]
  queryKey: string
  heading?: boolean
}>()

const route = useRoute()
const selectedOption = computed(
  () =>
    props.options.find(({ value }) => value === route.query[props.queryKey]) ??
    props.options[0]
)

const isOpen = ref(false)
const toggle = () => (isOpen.value = !isOpen.value)

const close = () => (isOpen.value = false)
const filtersRef = ref<HTMLElement>()

onClickOutside(filtersRef, close)
onKeyStroke('Escape', close)
watch(selectedOption, close)
</script>

<style lang="scss" scoped>
.filters {
  position: relative;
}

.active-filter {
  margin-top: rem(3px);
}

.toggle {
  display: flex;
  gap: rem(8px);
  align-items: center;
  text-align: left;
}

.toggle-icon {
  @include size(rem(14px));
  @include center-content;
  background-color: $black;
}

.chevron {
  @include size(rem(14px));
  transition: transform $transition;
  transform: rotate(180deg);

  .filters--hidden & {
    transform: rotate(0deg);
  }
}

.options {
  position: absolute;
  top: rem(26px);
  right: 0;
  z-index: z(filters);
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  width: rem(249px);
  padding: rem(24px);
  background-color: $white;

  @include media-up(lg) {
    position: unset;
    flex-direction: row;
    gap: rem(12px);
    width: unset;
    padding: 0;
    background-color: unset;
  }

  .filters--hidden & {
    @include media-down(lg) {
      display: none;
      pointer-events: none;
    }
  }
}

.option {
  @include focus-visible();
  display: flex;
  gap: rem(20px);
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-transform: uppercase;

  @include media-up(lg) {
    font-size: rem(22px);
    line-height: 1;
    transition: opacity $transition;

    &:not(.option--active) {
      opacity: 0.54;
    }
  }
}

.option-text {
  // align font
  padding-top: rem(4px);
}

.check {
  display: none;

  @include media-down(lg) {
    .option--active & {
      display: block;
    }
  }
}

.toggle,
.heading-toggle {
  @include focus-visible;
}
</style>
